$border-color: hsla(0, 0%, 75%, 1);
$horizontal-spacing: 18pt;
$vertical-spacing: 24pt;

@import "./vendor/reset.css";

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("/fonts/Inter/web/InterVariable.woff2") format("woff2");
}

:root { font-family: "Inter", sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: "InterVariable", sans-serif; font-optical-sizing: auto; }
}

body {
  max-width: 750pt;
  margin: 0 auto;
}

header {
  border-bottom: 1px solid $border-color;
  padding: $vertical-spacing 0;
  text-align: center;
}

h1 a {
  color: hsla(0, 0%, 40%, 1);
}

nav {
  border-bottom: 1px solid $border-color;
  padding: $vertical-spacing $horizontal-spacing;

  color: hsla(0, 0%, 40%, 1);
  font-weight: 500;
  font-size: 18pt;

  display: flex;
  gap: 0.5em;
  justify-content: center;
  flex-flow: row wrap;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

main {
  font-feature-settings: 'dlig' 1, 'calt' 1;
  font-weight: 300;
  font-size: 18pt;

  line-height: 1.5;

  > h1 {
    border-bottom: 1px solid $border-color;
    padding: $vertical-spacing $horizontal-spacing;
  }
}

article {
  border-bottom: 1px solid $border-color;

  & > * {
    margin: $vertical-spacing $horizontal-spacing;
  }

  &:last-child {
    border-bottom: 0;
  }

  h1 a {
    color: hsla(0, 0%, 40%, 1);
    text-decoration: none;
  }
}

p {
  margin: 27pt inherit;
}

a {
  color: #101099;
}

figure {
  margin: 18pt 0;
  margin-left: 0;
  margin-right: 0;

  width: 100%;

  figcaption {
    color: #999;
    font-size: 16pt;
    font-weight: 400;
    margin: 8pt 18pt;
  }

  img {
    width: 100%;
    height: auto;
  }

  picture {
    display: block;
    line-height: 0;
  }
}

h1 {
  font-weight: 700;
  font-size: 48pt;
}

main .metadata {
  display: flex;
  margin-top: 0;
  gap: 0.5em;
}

main h1,
main h2,
main h3,
main h4,
main h5,
main h6
{
  font-weight: bold;
  margin-bottom: 0;
}


main h1 {
  font-size: 24pt;
}

main h2 {
  font-size: 18pt;
}

main h2,
main time {
  margin-top: 0;
  font-weight: bold;
  font-size: 16pt;
  color: hsla(0, 0%, 30%, 1);
}

main h2 a {
  color: hsla(0, 0%, 30%, 1);
}

main h3 {
  font-weight: bold;
}

ul {
  list-style: inside;
}

nav.pagination {
  border-top: 1px solid $border-color;
  padding: 0;
  margin-bottom: $vertical-spacing;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;

  * {
    flex-grow: 1;
    font-weight: 400;
    padding: 24pt 0;
    text-decoration: none;
  }

  a:hover {
    background-color: hsla(0, 0%, 75%, 0.25);
  }

  .previous {
    border-right: 1px solid $border-color;
    padding-left: 18pt;
    text-align: left;
  }

  .next {
    padding-right: 18pt;
    text-align: right;
  }
}
